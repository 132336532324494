body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide the arrows for number input in Chrome, Safari, Edge, Opera */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrows for number input in Firefox */
.no-arrows[type=number] {
  -moz-appearance: textfield;
}

.toggle-button {
  width: 100%;
  padding: 10px;
  font-weight: bold;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.toggle-button:not(.selected) {
  background-color: #f8f9fa;
  color: #6c757d;
  border-color: #6c757d;
}

.toggle-button.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.toggle-button.selected:hover {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.toggle-button:not(.selected):hover {
  background-color: #f8f9fa;
  color: #6c757d;
  border-color: #6c757d;
}

.custom-checkbox input[type="checkbox"] {
  accent-color: #0d6efd;
  /* Bootstrap primary color */
  transform: scale(1.2);
  margin-right: 8px;
}

.custom-checkbox label {
  font-weight: 500;
  color: #495057;
}

.CCardBody {
  padding: 1.5rem;
}

.CCard {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.g-3>* {
  padding: 8px;
}